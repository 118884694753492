import { uniqBy } from 'lodash'

import {
  Port,
  TimelineDate,
  TimelineDateFullFragment,
  TimelineDatePayload,
  TimelineDateRevision,
  TimelineDateRevisionPayload,
} from '@graphql/server/typescript'

import { DynamicTimelineDate } from '@modules/TableView/types'
import EnumProvider from '@modules/app/providers/enum'
import { DateTimeString } from '@types'
import { isUnavailable } from '@utils/data'

import { initDatetimeToContainer } from './date'
import { getByPathWithDefault } from './fp'

export const getCurrentDate = (
  timelineDate?:
    | Partial<TimelineDatePayload>
    | DynamicTimelineDate
    | TimelineDateFullFragment
    | null
): DateTimeString | null => {
  if (timelineDate?.__typename !== 'TimelineDate') return null

  const { date, resultDate, timelineDateRevisions = [] } = timelineDate

  const parsedTimelineDateRevisions = timelineDateRevisions.flatMap((tdr) =>
    tdr.__typename === 'TimelineDateRevision' ? tdr : []
  )

  return (
    // Attempt to return resultDate
    resultDate ??
    (parsedTimelineDateRevisions.length
      ? parsedTimelineDateRevisions
          // Find the most recently added revision, return its date
          .reduce((max, current) => (max.createdAt > current.createdAt ? max : current)).date // If no TimelineDateRevisions return the date
      : date)
  )
}

export const getPortName = (
  enumType?: 'Seaport' | 'Airport' | 'Road' | 'Rail' | 'Dryport',
  portValue?: string | { description: string }
): React.ReactNode => {
  if (portValue && typeof portValue !== 'string' && portValue.description) {
    return String(portValue.description)
  }

  if (enumType && portValue) {
    return (
      <EnumProvider enumType={enumType}>
        {({ loading, error, data }) => {
          if (loading) return null
          if (error) return `Error!: ${error}`

          const searchedPort = data.find((portInList) => portInList.name === portValue)

          if (searchedPort) {
            return searchedPort.description
          }

          return 'Not found'
        }}
      </EnumProvider>
    )
  }
  return null
}

// TODO: add type here
export const getUniqueExporters = (batches: any[] = []) => {
  // $FlowFixMe need to change type from lodash
  const uniqueExporters = uniqBy(
    // @ts-ignore // TODO
    batches.map((batch) => getByPathWithDefault({}, 'orderItem.productProvider.exporter', batch)),
    'id'
  )

  return uniqueExporters
}

export const getPort = (port: Port | null | undefined): string => {
  return (
    port?.airportName ||
    port?.seaportName ||
    port?.railName ||
    port?.roadName ||
    port?.dryportName ||
    ''
  )
}

// TODO: make timeline point nullish optional type
export const initDatetimeToContainerForShipmentTimeline = (
  timelinePoint: TimelineDatePayload | { timelineDateRevisions: undefined } | null | undefined,
  timelinePointName: string,
  timezone: string
): { [key: string]: any } => {
  return timelinePoint
    ? {
        [timelinePointName]: {
          ...timelinePoint,
          ...initDatetimeToContainer(
            (timelinePoint as TimelineDate).date ?? null,
            'date',
            timezone
          ),
          ...initDatetimeToContainer(
            (timelinePoint as TimelineDate).resultDate ?? null,
            'resultDate',
            timezone
          ),
          // @ts-ignore // TODO
          timelineDateRevisions: (!isUnavailable(timelinePoint) &&
          timelinePoint.timelineDateRevisions
            ? timelinePoint.timelineDateRevisions
            : []
          ).map((revision: TimelineDateRevisionPayload) => ({
            ...revision,
            ...initDatetimeToContainer(
              (revision as TimelineDateRevision).date ?? null,
              'date',
              timezone
            ),
          })),
        },
      }
    : {}
}
