export const ORDER_FORM = 'order.orders.form'
export const ORDER_CREATE = 'order.orders.create'
export const ORDER_UPDATE = 'order.orders.update'

export const ORDER_DOCUMENT_GET = 'order.files.get'
export const ORDER_DOCUMENT_FORM = 'order.files.form'
export const ORDER_DOCUMENT_EDIT = 'order.files.edit'
export const ORDER_DOCUMENT_DOWNLOAD = 'order.files.download'
export const ORDER_DOCUMENT_DELETE = 'order.files.delete'

export const ORDER_DOCUMENT_GET_TYPE_PO = 'order.files.getByTypePO'
export const ORDER_DOCUMENT_GET_TYPE_PI = 'order.files.getByTypePI'

export const ORDER_SET_CUSTOM_FIELDS = 'order.orders.setCustomFields'
export const ORDER_SET_CUSTOM_FIELDS_MASK = 'order.orders.setCustomFieldsMask'
export const ORDER_SET_TAGS = 'order.orders.setTags'
export const ORDER_SET_MEMO = 'order.orders.setMemo'
export const ORDER_SET_ARCHIVED = 'order.orders.setArchived'
export const ORDER_SET_CURRENCY = 'order.orders.setCurrency'
export const ORDER_SET_DELIVERY_PLACE = 'order.orders.setDeliveryPlace'
export const ORDER_SET_DELIVERY_DATE = 'order.orders.setDeliveryDate'
export const ORDER_SET_EXPORTER = 'order.orders.setExporter'
export const ORDER_SET_IMPORTER = 'order.orders.setImporter'
export const ORDER_SET_INCOTERM = 'order.orders.setIncoterm'
export const ORDER_SET_ISSUE_AT = 'order.orders.setIssuedAt'
export const ORDER_SET_PI_NO = 'order.orders.setPiNo'
export const ORDER_SET_PO_NO = 'order.orders.setPoNo'
export const ORDER_SET_FOLLOWERS = 'order.orders.setFollowers'
