import iconWhite from '@web-app/next/public/media/icon_white.png'
import logoWhite from '@web-app/next/public/media/logo_white.png'

import Icon from '@components/Icon'
import { isSubPath, isNext } from '@utils/env'
import { getLocationOrigin } from '@utils/location'

import {
  IconImageWrapper,
  LogoButtonWrapper,
  LogoImageWrapper,
  LogoWrapper,
  ToggleButton,
} from './styles'

interface Props {
  isSideBarExpanded: boolean
  onSideBarToggleClick: (event: any) => void
}

const Logo = ({ isSideBarExpanded, onSideBarToggleClick }: Props) => (
  <LogoWrapper>
    <a href={`${getLocationOrigin()}/shipment/map`}>
      <LogoButtonWrapper>
        <IconImageWrapper>
          <img
            src={isNext ? `${isSubPath ? '/new' : ''}/media/icon_white.png` : iconWhite}
            alt="brand logo"
          />
        </IconImageWrapper>
        <LogoImageWrapper>
          <img
            src={isNext ? `${isSubPath ? '/new' : ''}/media/logo_white.png` : logoWhite}
            alt="brand name logo"
          />
        </LogoImageWrapper>
      </LogoButtonWrapper>
    </a>
    <ToggleButton type="button" tabIndex={-1} onClick={onSideBarToggleClick}>
      <Icon icon={isSideBarExpanded ? 'TOGGLE_ON' : 'TOGGLE_OFF'} />
    </ToggleButton>
  </LogoWrapper>
)

export default Logo
