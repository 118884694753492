// @flow
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import type { FilePayload } from '@graphql/server/flow'

import DocumentCard from 'components/Cards/DocumentCard'
import GridView from 'components/GridView'
import PartnerPermissionsWrapper from 'components/PartnerPermissionsWrapper'
import UploadPlaceholder from 'components/UploadPlaceholder'

import DefaultDocumentItem from './DefaultDocumentItem'

type RenderItemProps = {
  file: FilePayload,
  afterDelete?: (fileId: string) => void,
  onSelect?: (fileId: string) => void,
  isSelected?: boolean,
}

type Props = {
  files: FilePayload[],
  onLoadMore: Function,
  hasMore: boolean,
  isLoading: boolean,
  afterDelete?: (fileId: string) => void,
  onSelect?: (fileId: string) => void,
  selectedFiles?: { [key: string]: Object },
  renderItem?: (props: RenderItemProps) => React$Node,
}

const defaultRenderItem = ({
  file,
  afterDelete,
  onSelect,
  isSelected,
}: RenderItemProps): React$Node =>
  file?.uploading ? (
    <UploadPlaceholder progress={file?.progress ?? 0} height="184px" key={file?.id} />
  ) : (
    <PartnerPermissionsWrapper
      key={file?.id ?? ''}
      data={file}
      entityConnectionId={file?.entity?.connectionBy?.id}
    >
      {(permissions) => {
        return (
          <DefaultDocumentItem
            file={file}
            afterDelete={afterDelete}
            onSelect={onSelect}
            isSelected={isSelected}
            permissions={permissions}
          />
        )
      }}
    </PartnerPermissionsWrapper>
  )

const DocumentGridView = ({
  files,
  onLoadMore,
  hasMore,
  isLoading,
  afterDelete,
  onSelect,
  selectedFiles,
  renderItem = defaultRenderItem,
}: Props): React$Node => {
  return (
    <GridView
      onLoadMore={onLoadMore}
      hasMore={hasMore}
      isLoading={isLoading}
      itemWidth="195px"
      isEmpty={files.length === 0}
      emptyMessage={
        <FormattedMessage id="modules.Documents.noDocumentFound" defaultMessage="No files found" />
      }
    >
      {files.map((file) => {
        const isSelected = !!onSelect && !!selectedFiles && !!file.id && !!selectedFiles[file.id]

        if (file.__typename === 'Forbidden') return <DocumentCard file={file} />

        return renderItem({
          file,
          afterDelete,
          onSelect,
          isSelected,
        })
      })}
    </GridView>
  )
}

export default DocumentGridView
