// @flow
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import Icon from 'components/Icon'

import { StatusStyle, ToggleButtonStyle } from './style'

type Props = {
  readOnly?: boolean,
  openStatusDialog?: Function,
  activateDialog?: React.Node,
  archiveDialog?: React.Node,
  archived: boolean,
  onClick?: Function,
}

const StatusToggle = ({
  readOnly = false,
  archived,
  openStatusDialog = () => {},
  activateDialog,
  archiveDialog,
  onClick,
}: Props): React.Node => (
  <>
    {!readOnly && activateDialog}
    {!readOnly && archiveDialog}
    <div className={StatusStyle(archived)}>
      <Icon icon={archived ? 'ARCHIVE' : 'ACTIVE'} />
      {archived ? (
        <FormattedMessage id="components.form.archived" defaultMessage="Archived" />
      ) : (
        <FormattedMessage id="components.form.active" defaultMessage="Active" />
      )}
      {!readOnly && (
        <button
          type="button"
          className={ToggleButtonStyle(archived)}
          tabIndex={-1}
          onClick={onClick || openStatusDialog}
          data-testid="archivedStatusToggle"
        >
          {archived ? <Icon icon="TOGGLE_OFF" /> : <Icon icon="TOGGLE_ON" />}
        </button>
      )}
    </div>
  </>
)

export default StatusToggle
