// @flow
import { Link } from '@reach/router'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import type {
  BatchPayload,
  OrderItemPayload,
  OrderPayload,
  ProductPayload,
  ProductProviderPayload,
} from '@graphql/server/flow'
import { ORDER_ITEMS_GET_PRICE } from '@modules/permission/constants/orderItem'

import { Display, FieldItem, Label, NumberInputFactory, TextInputFactory } from 'components/Form'
import FormattedNumber from 'components/FormattedNumber'
import Icon from 'components/Icon'
import ProductImage from 'components/ProductImage'
import QuantityChart from 'components/QuantityChart'
import RelateEntity from 'components/RelateEntity'
import Tag from 'components/Tag'
import withForbiddenCard from 'hoc/withForbiddenCard'
import usePartnerPermission from 'hooks/usePartnerPermission'
import usePermission from 'hooks/usePermission'
import { FormField } from 'modules/form'
import { encodeId } from 'utils/id'
import { getItemQuantityChartData } from 'utils/item'
import { times } from 'utils/number'

import BaseCard from '../BaseCard'

import {
  BodyWrapperStyle,
  ChartWrapperStyle,
  DividerStyle,
  ImporterWrapperStyle,
  ItemTagsWrapperStyle,
  NoWrapperStyle,
  OrderItemCardWrapperStyle,
  OrderWrapperStyle,
  ProductIconLinkStyle,
  ProductImageStyle,
  ProductInfoWrapperStyle,
  ProductNameStyle,
  ProductNameWrapperStyle,
  ProductProviderNameStyle,
  ProductSerialStyle,
  ProductTagsWrapperStyle,
  ProductWrapperStyle,
  QuantityWrapperStyle,
  SyncButtonStyle,
  TagsAndTaskWrapperStyle,
  TotalPriceWrapperStyle,
  UnitPriceWrapperStyle,
} from './style'
import validator from './validator'

type Props = {|
  order: OrderPayload,
  batches: BatchPayload[],
  index: number,
  actions: React$Node[],
  setFieldValue: (path: string, value: mixed) => void,
  onClick: Function,
  editable: {
    quantity: boolean,
    price: boolean,
  },
  viewable: {
    price: boolean,
  },
  navigable: {
    product: boolean,
    order: boolean,
  },
  config: {
    hideOrder: boolean,
  },
  selectable: boolean,
  selected: boolean,
  onSelect: Function,
  readOnly: boolean,
  orderItem: OrderItemPayload,
  productProvider: ?ProductProviderPayload,
  product: ?ProductPayload,
|}

const defaultProps = {
  order: {
    id: '',
    poNo: '',
  },
  batches: [],
  index: 0,
  actions: [],
  setFieldValue: () => {},
  onClick: () => {},
  selectable: false,
  selected: false,
  onSelect: () => {},
  readOnly: false,
}

const editableDefault = {
  no: false,
  quantity: false,
  price: false,
}

const navigableDefault = {
  product: false,
  order: false,
}

const configDefault = {
  hideOrder: false,
}

let cardHeight = 358

const ItemCard = ({
  orderItem,
  productProvider,
  product,
  order,
  batches,
  index,
  setFieldValue,
  onClick,
  editable,
  viewable,
  navigable,
  config,
  ...rest
}: Props) => {
  const { isOwner } = usePartnerPermission()
  const { hasPermission } = usePermission(isOwner)

  let archived = false
  let no = ''
  let quantity = 0
  let price = null
  let tags = []

  if (orderItem.__typename === 'OrderItem') {
    archived = orderItem.archived ?? archived
    no = orderItem.no ?? no
    quantity = orderItem.quantity ?? quantity
    price = orderItem.price ?? price
    tags = orderItem.tags ?? tags
  }

  let orderId = ''
  let poNo = ''
  let orderCurrency = ''
  let importer = null
  let exporter = null

  if (order.__typename === 'Order') {
    orderId = order.id ?? orderId
    poNo = order.poNo ?? poNo
    orderCurrency = order.currency ?? orderCurrency
    importer = order.importer ?? importer
    exporter = order.exporter ?? exporter
  }

  const validation = validator({
    no: `orderItems.${index}.no`,
    quantity: `orderItems.${index}.quantity`,
    price: `orderItems.${index}.price`,
  })

  const values = {
    [`orderItems.${index}.no`]: no,
    [`orderItems.${index}.quantity`]: quantity,
    [`orderItems.${index}.price`]: price,
    orderCurrency,
  }

  let productProviderName = ''
  let productUnitPrice = null

  if (productProvider?.__typename === 'ProductProvider') {
    productProviderName = productProvider.name ?? productProviderName
    productUnitPrice = productProvider.unitPrice ?? productUnitPrice
  }

  let productId = ''
  let productName = ''
  let productSerial = ''
  let productTags = []

  if (product?.__typename === 'Product') {
    productId = product.id ?? productId
    productName = product.name ?? productName
    productSerial = product.serial ?? productSerial
    productTags = product.tags ?? productTags
  }

  const quantityChartData = getItemQuantityChartData({ orderItem, batches })

  /* $FlowFixMe This comment suppresses an error found when upgrading Flow to
   * v0.111.0. To view the error, delete this comment and run Flow. */
  const mergedEditable = { ...editableDefault, ...editable }
  const mergedNavigable = { ...navigableDefault, ...navigable }
  const mergedConfig = { ...configDefault, ...config }

  if (!mergedConfig.hideOrder) cardHeight -= 71

  return (
    <BaseCard
      icon="ORDER_ITEM"
      color="ORDER_ITEM"
      showActionsOnHover
      isArchived={archived}
      {...rest}
    >
      <div className={OrderItemCardWrapperStyle} onClick={onClick} role="presentation">
        <div className={ProductWrapperStyle}>
          <ProductImage
            className={ProductImageStyle}
            height="95px"
            file={
              product?.__typename === 'Product' && product.files[0]?.__typename === 'File'
                ? product.files[0]
                : null
            }
          />
          <div className={ProductInfoWrapperStyle}>
            {productId && (
              <>
                <div className={ProductNameWrapperStyle}>
                  {mergedNavigable.product ? (
                    // $FlowFixMe Flow typed is not updated yet
                    <Link
                      className={ProductIconLinkStyle}
                      to={`/product/${encodeId(productId)}`}
                      onClick={(evt) => {
                        evt.stopPropagation()
                      }}
                    >
                      <Icon icon="PRODUCT" />
                    </Link>
                  ) : (
                    <div className={ProductIconLinkStyle}>
                      <Icon icon="PRODUCT" />
                    </div>
                  )}

                  <div className={ProductNameStyle}>{productName}</div>
                </div>

                <div className={ProductSerialStyle}>{productSerial}</div>

                <div className={ProductProviderNameStyle}>
                  <Icon icon="PRODUCT_PROVIDER" />
                  {productProviderName}
                </div>

                <div className={ProductTagsWrapperStyle}>
                  {productTags &&
                    productTags.flatMap((tag) => (tag.id ? <Tag key={tag.id} tag={tag} /> : []))}
                </div>
              </>
            )}
          </div>
        </div>

        <div className={BodyWrapperStyle}>
          <div
            className={NoWrapperStyle}
            onClick={(evt) => {
              if (mergedEditable.no) {
                evt.stopPropagation()
              }
            }}
            role="presentation"
          >
            <FormField
              name={`orderItems.${index}.no`}
              initValue={no}
              setFieldValue={setFieldValue}
              values={values}
              validator={validation}
            >
              {({ name, ...inputHandlers }) => (
                <TextInputFactory
                  name={name}
                  {...inputHandlers}
                  editable={mergedEditable.no}
                  inputWidth="185px"
                  inputHeight="20px"
                  inputAlign="left"
                  hideTooltip
                  required
                />
              )}
            </FormField>
          </div>

          <div
            className={QuantityWrapperStyle}
            onClick={(evt) => {
              if (mergedEditable.quantity) {
                evt.stopPropagation()
              }
            }}
            role="presentation"
          >
            <FormField
              name={`orderItems.${index}.quantity`}
              initValue={quantity}
              setFieldValue={setFieldValue}
              values={values}
              validator={validation}
            >
              {({ name, ...inputHandlers }) => (
                <NumberInputFactory
                  name={name}
                  {...inputHandlers}
                  editable={mergedEditable.quantity}
                  inputWidth="90px"
                  inputHeight="20px"
                  hideTooltip
                  required
                  label={<FormattedMessage id="components.cards.qty" defaultMessage="QTY" />}
                  labelWidth="90px"
                  labelHeight="20px"
                />
              )}
            </FormField>
          </div>

          <div
            className={UnitPriceWrapperStyle}
            onClick={(evt) => {
              if (mergedEditable.price) {
                evt.stopPropagation()
              }
            }}
            role="presentation"
          >
            <FormField
              name={`orderItems.${index}.price.amount`}
              initValue={price && price.__typename === 'NewPrice' ? price.amount : 0}
              setFieldValue={setFieldValue}
              values={values}
              validator={validation}
            >
              {({ name, ...inputHandlers }) => (
                <NumberInputFactory
                  name={name}
                  {...inputHandlers}
                  editable={mergedEditable.price}
                  blackout={!hasPermission(ORDER_ITEMS_GET_PRICE)}
                  inputWidth="90px"
                  inputHeight="20px"
                  hideTooltip
                  required
                  label={<FormattedMessage id="components.cards.price" defaultMessage="PRICE" />}
                  labelWidth="90px"
                  labelHeight="20px"
                  suffix={orderCurrency}
                />
              )}
            </FormField>

            {mergedEditable.price && (
              <button
                className={SyncButtonStyle}
                type="button"
                onClick={() => {
                  if (productUnitPrice) {
                    if (productUnitPrice.currency === orderCurrency) {
                      setFieldValue(
                        `orderItems.${index}.price.amount`,
                        productUnitPrice.__typename === 'NewPrice' ? productUnitPrice.amount : 0
                      )
                    }
                  }
                }}
              >
                <FormattedMessage id="components.cards.sync" defaultMessage="SYNC" />
                <Icon icon="SYNC" />
              </button>
            )}
          </div>

          <div className={DividerStyle} />

          <div className={ChartWrapperStyle}>
            <QuantityChart hasLabel={false} {...quantityChartData} />
          </div>

          <div className={TotalPriceWrapperStyle}>
            <FieldItem
              label={
                <Label>
                  <FormattedMessage id="components.cards.total" defaultMessage="TOTAL" />
                </Label>
              }
              input={
                <Display blackout={!hasPermission(ORDER_ITEMS_GET_PRICE)} width="90px">
                  <FormattedNumber
                    value={times(
                      (price && price.__typename === 'NewPrice' && price.amount) || 0,
                      quantity
                    )}
                    suffix={
                      orderCurrency ||
                      (price && price.__typename !== 'Price' && price.currency) ||
                      ''
                    }
                  />
                </Display>
              }
            />
          </div>

          {!mergedConfig.hideOrder && (
            <>
              <div className={OrderWrapperStyle}>
                <RelateEntity
                  link={mergedNavigable.order ? `/order/${encodeId(orderId)}` : ''}
                  entity="ORDER"
                  value={poNo}
                />
              </div>
              <div className={ImporterWrapperStyle}>
                <Icon icon="IMPORTER" />
                {importer && importer.__typename === 'Organization' && importer.name}
              </div>
              <div className={ImporterWrapperStyle}>
                <Icon icon="EXPORTER" />
                {exporter && exporter.__typename === 'Organization' && exporter.name}
              </div>
            </>
          )}

          <div className={TagsAndTaskWrapperStyle}>
            <div className={ItemTagsWrapperStyle}>
              {tags &&
                tags.flatMap((tag) =>
                  tag.__typename === 'Tag' ? <Tag key={tag.id} tag={tag} /> : []
                )}
            </div>
          </div>
        </div>
      </div>
    </BaseCard>
  )
}

ItemCard.defaultProps = defaultProps

export default (withForbiddenCard(ItemCard, 'orderItem', {
  width: '195px',
  height: `${cardHeight}px`,
  entityIcon: 'ORDER_ITEM',
  entityColor: 'ORDER_ITEM',
}): any)
