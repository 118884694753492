// import setNextTranslateLanguage from 'next-translate/setLanguage';
import { ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale/en_US'
import jaJP from 'antd/lib/locale/ja_JP'
import zhCN from 'antd/lib/locale/zh_CN'
import { useViewer } from 'contexts/Auth'
import {
  createContext,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { IntlProvider } from 'react-intl'

import { colors } from '@styles/common'

import { getInitialLocale, translationMessages } from './helpers'

type Context = {
  language: string
  setLanguage: (param: string) => void
}

const LanguageContext = createContext<Context>({
  language: 'en',
  setLanguage: () => {},
})

export const useLanguage = (): Context => useContext(LanguageContext)

interface Props {
  children: React.ReactNode
}

type languageTypes = 'en' | 'ja' | 'zh'

// we use next-translate for string formats and react-intl for date formats
export const LanguageProvider = ({ children }: Props) => {
  const { user } = useViewer()

  const [reactIntlLanguage, setIntlLanguage] = useState(getInitialLocale())
  const appLang = useRef(reactIntlLanguage)

  const setAppLanguage = useCallback((newLanguage: languageTypes) => {
    // sets next-translate and react-intl languages
    if (appLang.current !== newLanguage) {
      // setNextTranslateLanguage(newLanguage);
      setIntlLanguage(newLanguage)
    }

    appLang.current = newLanguage
  }, [])

  useEffect(() => {
    if (user && user.language) {
      setAppLanguage(user.language as languageTypes)
    }
  }, [user, setAppLanguage])

  // Set ant d locale
  const determineLocale = () => {
    switch (reactIntlLanguage) {
      case 'ja':
        return jaJP
      case 'zh':
        return zhCN
      default:
        return enUS
    }
  }

  const antDesignLocale = determineLocale()

  return (
    <LanguageContext.Provider value={{ language: reactIntlLanguage, setLanguage: setAppLanguage }}>
      {/* You may be wondering what ConfigProvider is doing here, and let me tell you,
      I am also wondering why on earth it is here. */}
      <ConfigProvider
        locale={antDesignLocale}
        theme={{
          token: {
            colorPrimary: colors.TEAL,
            colorText: 'rgba(0, 0, 0, 0.8)',
            borderRadius: 6,
            fontFamily: 'inherit',
          },
        }}
      >
        <IntlProvider
          messages={translationMessages[reactIntlLanguage]}
          locale={reactIntlLanguage}
          textComponent={Fragment}
        >
          {children}
        </IntlProvider>
      </ConfigProvider>
    </LanguageContext.Provider>
  )
}
