// @flow
import * as React from 'react'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import type { ProductProvider } from '@graphql/server/flow'
import { PRODUCT_PROVIDER_GET_UNIT_PRICE } from '@modules/permission/constants/product'

import { Display, FormTooltip, Label } from 'components/Form'
import FormattedNumber from 'components/FormattedNumber'
import Icon from 'components/Icon'
import ProductImage from 'components/ProductImage'
import Tag from 'components/Tag'
import withForbiddenCard from 'hoc/withForbiddenCard'
import usePartnerPermission from 'hooks/usePartnerPermission'
import usePermission from 'hooks/usePermission'

import BaseCard from '../BaseCard'

import {
  ProductExporterStyle,
  ProductImageChevronButtonStyle,
  ProductImageDotStyle,
  ProductImageDotsWrapperStyle,
  ProductImageStyle,
  ProductImageWrapperStyle,
  ProductImporterStyle,
  ProductInfoWrapperStyle,
  ProductNameStyle,
  ProductPriceStyle,
  ProductProviderCardWrapperStyle,
  ProductProviderNameStyle,
  ProductProvidersWrapperStyle,
  ProductSerialStyle,
  ProductSupplierStyle,
  ProductTagsWrapperStyle,
  TagsAndTaskWrapperStyle,
} from './style'

type Props = {|
  onClick?: ?Function,
  selectable: boolean,
  selected: boolean,
  orderCurrency?: string,
  productProvider: ProductProvider,
|}

const OrderProductProviderCard = ({
  onClick,
  selectable = false,
  selected = false,
  orderCurrency,
  productProvider,
  ...rest
}: Props) => {
  const { isOwner } = usePartnerPermission()
  const { hasPermission } = usePermission(isOwner)
  const [activeImage, setActiveImage] = useState<number>(0)

  const actions = []

  let name = ''
  let serial = ''
  let tags = []
  let files = []
  let ownedBy = null

  if (productProvider.product.__typename === 'Product') {
    name = productProvider.product.name
    serial = productProvider.product.serial
    tags = productProvider.product.tags
    files = productProvider.product.files
    ownedBy = productProvider.product.ownedBy
  }

  const { archived, unitPrice } = productProvider

  const navigateImages = (direction: 'left' | 'right') => {
    if (productProvider) {
      if (direction === 'left') {
        if (activeImage > 0) setActiveImage((prev) => prev - 1)
      } else if (direction === 'right') {
        if (
          productProvider.product.__typename === 'Product' &&
          activeImage < productProvider.product.files.length - 1
        )
          setActiveImage((prev) => prev + 1)
      }
    }
  }

  if (orderCurrency && unitPrice && orderCurrency !== unitPrice.currency) {
    actions.push(
      <FormTooltip
        infoMessage={
          <FormattedMessage
            id="components.cards.currencyDifferentWarningMessage"
            defaultMessage="The Unit Price will not be automatically synced into the Item because the Currency of the Unit Price of this End Product does not match the Currency of this Order."
          />
        }
      />
    )
  }

  return (
    <BaseCard
      icon="PRODUCT_PROVIDER"
      color="PRODUCT_PROVIDER"
      actions={actions}
      selectable={selectable}
      forceShowActions={selected}
      isArchived={archived}
      selected={selected}
      {...rest}
    >
      <div className={ProductProviderCardWrapperStyle} onClick={onClick} role="presentation">
        <div className={ProductImageWrapperStyle}>
          <ProductImage height="75px" className={ProductImageStyle} file={files[activeImage]} />
          {files && activeImage > 0 && (
            <button
              className={ProductImageChevronButtonStyle('left')}
              onClick={(evt) => {
                evt.stopPropagation()
                navigateImages('left')
              }}
              type="button"
            >
              <Icon icon="ANGLE_LEFT" />
            </button>
          )}
          {files && activeImage < files.length - 1 && (
            <button
              className={ProductImageChevronButtonStyle('right')}
              onClick={(evt) => {
                evt.stopPropagation()
                navigateImages('right')
              }}
              type="button"
            >
              <Icon icon="ANGLE_RIGHT" />
            </button>
          )}
          <div className={ProductImageDotsWrapperStyle}>
            {files &&
              files.length > 1 &&
              files.flatMap((file, index) =>
                file.__typename === 'File' ? (
                  <div className={ProductImageDotStyle(activeImage === index)} key={file.id} />
                ) : (
                  []
                )
              )}
          </div>
        </div>

        <div className={ProductInfoWrapperStyle}>
          <div className={ProductNameStyle}>{name}</div>
          <div className={ProductSerialStyle}>{serial}</div>
          <div className={ProductPriceStyle}>
            <Label>
              <FormattedMessage id="modules.Orders.price" defaultMessage="Unit Price" />
            </Label>
            <Display blackout={!hasPermission(PRODUCT_PROVIDER_GET_UNIT_PRICE)}>
              <FormattedNumber
                value={unitPrice && unitPrice.amount}
                suffix={unitPrice && unitPrice.currency}
              />
            </Display>
          </div>
          <div className={ProductProvidersWrapperStyle}>
            <div className={ProductProviderNameStyle}>{productProvider.name}</div>
            <div className={ProductImporterStyle}>
              <Icon icon="IMPORTER" />
              {ownedBy?.__typename === 'Organization' && ownedBy.name}
            </div>
            <div className={ProductExporterStyle}>
              <Icon icon="EXPORTER" />
              {productProvider?.exporter.__typename === 'Organization' &&
                productProvider.exporter.name}
            </div>
            <div className={ProductSupplierStyle}>
              <Icon icon="SUPPLIER" />
              {productProvider.supplier?.__typename === 'Organization' &&
                productProvider.supplier.name}
            </div>
          </div>

          <div className={TagsAndTaskWrapperStyle}>
            <div className={ProductTagsWrapperStyle}>
              {tags &&
                tags.length > 0 &&
                tags.flatMap((tag) => (tag.id ? <Tag key={tag.id} tag={tag} /> : []))}
            </div>
          </div>
        </div>
      </div>
    </BaseCard>
  )
}

export default (withForbiddenCard(OrderProductProviderCard, 'productProvider', {
  width: '195px',
  height: '284px',
  entityIcon: 'PRODUCT_PROVIDER',
  entityColor: 'PRODUCT_PROVIDER',
}): any)
