// @flow strict
import type { ApolloQueryResult } from '@apollo/client'
import { navigate } from '@reach/router'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { BooleanValue } from 'react-values'

import type { ShipmentListFragment, ShipmentListQuery } from '@graphql/server/flow'
import {
  SHIPMENT_ARCHIVE,
  SHIPMENT_CREATE,
  SHIPMENT_GET,
} from '@modules/permission/constants/shipment'

import { CardAction, ShipmentCard } from 'components/Cards'
import GridView from 'components/GridView'
import PartnerPermissionsWrapper from 'components/PartnerPermissionsWrapper'
import { ShipmentActivateDialog, ShipmentArchiveDialog } from 'modules/shipment/common/Dialog'
import type { ExplicitPayload } from 'types'
import { encodeId } from 'utils/id'

const defaultRenderItem = (item: ExplicitPayload<ShipmentListFragment>): React$Node => (
  <PartnerPermissionsWrapper data={item} key={item.id}>
    {(permissions) => (
      <BooleanValue>
        {({ value: statusDialogIsOpen, set: dialogToggle }) => (
          <>
            {item.archived === false ? (
              <ShipmentActivateDialog
                onRequestClose={() => dialogToggle(false)}
                isOpen={statusDialogIsOpen}
                shipment={item}
              />
            ) : (
              <ShipmentArchiveDialog
                onRequestClose={() => dialogToggle(false)}
                isOpen={statusDialogIsOpen}
                shipment={item}
              />
            )}
            <ShipmentCard
              shipment={item}
              actions={[
                permissions.includes(SHIPMENT_CREATE) && item.__typename === 'Shipment' && (
                  <CardAction
                    icon="CLONE"
                    onClick={() => navigate(`/shipment/clone/${encodeId(item.id)}`)}
                  />
                ),
                permissions.includes(SHIPMENT_ARCHIVE) && item.__typename === 'Shipment' && (
                  <CardAction
                    icon={item.archived === false ? 'ACTIVE' : 'ARCHIVE'}
                    onClick={() => dialogToggle(true)}
                  />
                ),
              ].filter(Boolean)}
              showActionsOnHover
              {...(item.__typename === 'Shipment' && {
                onClick: () => {
                  if (permissions.some((p) => SHIPMENT_GET.includes(p))) {
                    navigate(`/shipment/${encodeId(item.id)}`)
                  }
                },
              })}
            />
          </>
        )}
      </BooleanValue>
    )}
  </PartnerPermissionsWrapper>
)

type Props = {
  items: ExplicitPayload<ShipmentListFragment>[],
  onLoadMore: () => Promise<void | ApolloQueryResult<ShipmentListQuery>> | void,
  hasMore: boolean,
  isLoading: boolean,
  renderItem?: (item: ExplicitPayload<ShipmentListFragment>) => React.Node,
}

const ShipmentGridView = ({
  items,
  onLoadMore,
  hasMore,
  isLoading,
  renderItem = defaultRenderItem,
}: Props): React.Node => {
  return (
    <GridView
      onLoadMore={onLoadMore}
      hasMore={hasMore}
      isLoading={isLoading}
      itemWidth="860px"
      isEmpty={items.length === 0}
      emptyMessage={
        <FormattedMessage id="modules.Shipments.noItem" defaultMessage="No shipments found" />
      }
    >
      {items.map(renderItem)}
    </GridView>
  )
}

export default ShipmentGridView
