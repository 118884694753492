// @flow
import { useQuery } from '@apollo/client'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import tagsByIDsQuery from '@graphql/client/tag/query.tagsByIDs.graphql'
import { TAG_GET } from '@modules/permission/constants/tag'

import { Label, TagsInput } from 'components/Form'
import { useViewerHasPermissions } from 'contexts/Permissions'

import messages from '../../messages'
import type { FilterInputProps } from '../../types'

type ImplProps = {
  ...FilterInputProps<string[]>,
  tagType: string,
}

const TagsImpl = ({ value, readonly, onChange, tagType }: ImplProps) => {
  const hasPermissions = useViewerHasPermissions()
  const { data } = useQuery(tagsByIDsQuery, {
    variables: { ids: value },
    fetchPolicy: 'cache-and-network',
  })

  return (
    <>
      <Label height="30px">
        <FormattedMessage {...messages.tags} />
      </Label>

      <TagsInput
        name="tags"
        width="200px"
        tagType={tagType}
        disabled={readonly}
        values={data?.tagsByIDs ?? []}
        onChange={(newTags) => {
          onChange(newTags.map((t) => t.id))
        }}
        onClickRemove={(removedTag) => {
          onChange(value.filter((id) => id !== removedTag.id))
        }}
        editable={{
          set: hasPermissions(TAG_GET),
          remove: true,
        }}
      />
    </>
  )
}

const Tags =
  (tagType: string): ((FilterInputProps<string[]>) => React.Node) =>
  ({ value, onChange, readonly }: FilterInputProps<string[]>) =>
    <TagsImpl value={value} readonly={readonly} onChange={onChange} tagType={tagType} />

export const ProductTags: (FilterInputProps<string[]>) => React.Node = Tags('Product')
export const OrderTags: (FilterInputProps<string[]>) => React.Node = Tags('Order')
export const OrderItemTags: (FilterInputProps<string[]>) => React.Node = Tags('OrderItem')
export const BatchTags: (FilterInputProps<string[]>) => React.Node = Tags('Batch')
export const ShipmentTags: (FilterInputProps<string[]>) => React.Node = Tags('Shipment')
export const ContainerTags: (FilterInputProps<string[]>) => React.Node = Tags('Container')
export const UserTags: (FilterInputProps<string[]>) => React.Node = Tags('User')
export const FileTags: (FilterInputProps<string[]>) => React.Node = Tags('File')

export default Tags
