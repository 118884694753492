import { FormattedMessage } from 'react-intl'

import messages from './messages'

interface Props {
  showOnlyOneName?: boolean
  firstName?: string
  lastName?: string
}

const FormattedName = ({ firstName = '', lastName = '', showOnlyOneName }: Props) => {
  if (showOnlyOneName) {
    return <>{firstName}</>
  }

  return <FormattedMessage {...messages.name} values={{ firstName, lastName }} />
}

export default FormattedName
