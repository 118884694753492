import getZenboxName from '@components/ZenboxLabel/helpers'

import { ZenboxLabelWrapperStyle } from './style'

interface Props {
  name: string
  ownerId?: string
  width?: number
}

const ZenboxLabel = ({ name, ownerId, width }: Props) => (
  <ZenboxLabelWrapperStyle width={width}>
    Zenbox <br /> {getZenboxName({ name, ownerId })}
  </ZenboxLabelWrapperStyle>
)

export default ZenboxLabel
