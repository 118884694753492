import { Location } from '@reach/router'
import { useViewerHasPermissions } from 'contexts/Permissions'
import AllLogBoard from 'modules/allLogBoard'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { isEnableBetaFeature } from 'utils/env'

import {
  NAVIGATION_ORDERS_MAP,
  NAVIGATION_ORDERS_CARD,
  NAVIGATION_ORDER_ITEMS_CARD,
  NAVIGATION_BATCH_CARD,
  NAVIGATION_SHIPMENTS_MAP,
  NAVIGATION_SHIPMENTS_TABLE_BETA,
  NAVIGATION_SHIPMENTS_CARD,
  NAVIGATION_CONTAINERS_CARD,
  NAVIGATION_PRODUCTS_CARD,
  NAVIGATION_DOCUMENTS_CARD,
  NAVIGATION_NETWORK_WAREHOUSES,
  NAVIGATION_NETWORK_PARTNERS,
  NAVIGATION_NETWORK_USERS,
  NAVIGATION_TEMPLATES_CUSTOM_FILEDS,
  NAVIGATION_TEMPLATES_TABLE,
  NAVIGATION_TAGS_CARD,
  NAVIGATION_ADMIN_ACTIVITYLOG,
} from '@modules/permission/constants/navigation'
import { isNext } from '@utils/env'

import { Logo, MenuItem, SubMenu } from './components'
import messages from './messages'
import { SideBarMenuStyle, SideBarWrapper } from './styles'
import { MenuConfig } from './types'

interface Props {
  isSideBarExpanded: boolean
  onSideBarToggleClick: () => void
}

const SideBar = ({ isSideBarExpanded, onSideBarToggleClick }: Props) => {
  const intl = useIntl()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const router = isNext ? useRouter() : null

  React.useEffect(() => {
    if (isNext) router?.prefetch('/login')

    // eslint-disable-next-line
  }, [])

  const hasPermissions = useViewerHasPermissions()

  let pathnameSplit = isNext ? router?.asPath.split('?')[0]?.split('/') : undefined

  const menuItems: MenuConfig[] = [
    {
      key: 'activity-log',
      label: messages.activityLog,
      icon: 'WAVE_PULSE',
      component: {
        activeState: useState(false),
        render: ({ isActive, setActive }) => (
          <AllLogBoard isActive={isActive} setActive={setActive} />
        ),
      },
      permissions: NAVIGATION_ADMIN_ACTIVITYLOG,
      beta: true,
    },
    {
      label: messages.order,
      icon: 'ORDER',
      path: 'order',
      permissions: [NAVIGATION_ORDERS_MAP, NAVIGATION_ORDERS_CARD],
      submenu: [
        {
          label: messages.map,
          icon: 'MAP',
          path: 'map',
          internal: !isNext,
          permissions: NAVIGATION_ORDERS_MAP,
        },
        {
          label: messages.newTable,
          icon: 'TABLE',
          key: 'newTable',
          path: isNext ? 'table' : 'newTable',
          href: !isNext ? `${window.location.origin}/new/order/table` : undefined,
          internal: isNext,
        },
        {
          label: messages.cards,
          icon: 'CARDS',
          path: 'cards',
          internal: !isNext,
          permissions: NAVIGATION_ORDERS_CARD,
        },
      ],
    },
    {
      label: messages.orderItem,
      icon: 'ORDER_ITEM',
      path: 'order-item',
      permissions: NAVIGATION_ORDER_ITEMS_CARD,
      submenu: [
        {
          label: messages.cards,
          icon: 'CARDS',
          path: 'cards',
          internal: !isNext,
        },
      ],
    },
    {
      label: messages.batch,
      icon: 'BATCH',
      path: 'batch',
      permissions: [NAVIGATION_BATCH_CARD],
      submenu: [
        {
          label: messages.newTable,
          icon: 'TABLE',
          key: 'newTable',
          path: isNext ? 'table' : 'newTable',
          href: !isNext ? `${window.location.origin}/new/batch/table` : undefined,
          internal: isNext,
        },
        {
          label: messages.cards,
          icon: 'CARDS',
          path: 'cards',
          internal: !isNext,
          permissions: NAVIGATION_BATCH_CARD,
        },
      ],
    },
    {
      label: messages.shipment,
      icon: 'SHIPMENT',
      path: 'shipment',
      permissions: [
        NAVIGATION_SHIPMENTS_MAP,
        NAVIGATION_SHIPMENTS_TABLE_BETA,
        NAVIGATION_SHIPMENTS_CARD,
      ],
      submenu: [
        {
          label: messages.map,
          icon: 'MAP',
          path: 'map',
          internal: !isNext,
          permissions: NAVIGATION_SHIPMENTS_MAP,
        },
        {
          label: messages.newTable,
          icon: 'TABLE',
          key: 'newTable',
          path: isNext ? 'table' : 'newTable',
          href: !isNext ? `${window.location.origin}/new/shipment/table` : undefined,
          internal: isNext,
          permissions: NAVIGATION_SHIPMENTS_TABLE_BETA,
        },
        {
          label: messages.cards,
          icon: 'CARDS',
          path: 'cards',
          internal: !isNext,
          permissions: NAVIGATION_SHIPMENTS_CARD,
        },
      ],
    },
    {
      label: messages.container,
      icon: 'CONTAINER',
      path: 'container',
      permissions: NAVIGATION_CONTAINERS_CARD,
      submenu: [
        {
          label: messages.cards,
          icon: 'CARDS',
          path: 'cards',
          internal: !isNext,
        },
      ],
    },
    {
      label: messages.product,
      icon: 'PRODUCT',
      path: 'product',
      permissions: NAVIGATION_PRODUCTS_CARD,
      submenu: [
        {
          label: messages.cards,
          icon: 'CARDS',
          path: 'cards',
          internal: !isNext,
        },
      ],
    },
    {
      label: messages.documents,
      icon: 'DOCUMENT',
      path: 'document',
      permissions: NAVIGATION_DOCUMENTS_CARD,
      submenu: [
        {
          label: messages.cards,
          icon: 'CARDS',
          path: 'cards',
          internal: !isNext,
        },
        {
          label: messages.folder,
          icon: 'FOLDER',
          path: 'folder',
          internal: !isNext,
        },
      ],
    },
    {
      label: messages.network,
      icon: 'NETWORK',
      path: 'network',
      permissions: [
        NAVIGATION_NETWORK_WAREHOUSES,
        NAVIGATION_NETWORK_PARTNERS,
        NAVIGATION_NETWORK_USERS,
      ],
      submenu: [
        {
          label: messages.warehouse,
          icon: 'WAREHOUSE',
          path: 'warehouse',
          internal: !isNext,
          overrideFullPath: 'warehouse',
          permissions: NAVIGATION_NETWORK_WAREHOUSES,
        },
        {
          label: messages.partner,
          icon: 'PARTNER',
          path: 'partner',
          internal: !isNext,
          overrideFullPath: 'partner',
          permissions: NAVIGATION_NETWORK_PARTNERS,
        },
        {
          label: messages.user,
          icon: 'USER',
          path: 'staff',
          internal: !isNext,
          overrideFullPath: 'staff',
          permissions: NAVIGATION_NETWORK_USERS,
        },
      ],
    },
    {
      label: messages.templates,
      icon: 'TEMPLATE',
      path: 'templates',
      permissions: [NAVIGATION_TEMPLATES_CUSTOM_FILEDS, NAVIGATION_TEMPLATES_TABLE],
      submenu: [
        {
          label: messages.metadata,
          icon: 'METADATA',
          path: 'metadata',
          internal: !isNext,
          permissions: NAVIGATION_TEMPLATES_CUSTOM_FILEDS,
        },
        {
          label: messages.table,
          icon: 'EDIT_TABLE',
          path: 'table-template',
          internal: !isNext,
          permissions: NAVIGATION_TEMPLATES_TABLE,
        },
      ],
    },
    {
      label: messages.tags,
      icon: 'TAG',
      path: 'tags',
      permissions: NAVIGATION_TAGS_CARD,
      submenu: [
        {
          label: messages.cards,
          icon: 'CARDS',
          path: 'cards',
          internal: !isNext,
        },
      ],
    },
  ]

  return (
    <Location>
      {({ location }) => {
        if (!isNext) pathnameSplit = location.pathname.split('?')[0].split('/')

        return (
          <SideBarWrapper isSideBarExpanded={isSideBarExpanded}>
            <Logo
              isSideBarExpanded={isSideBarExpanded}
              onSideBarToggleClick={onSideBarToggleClick}
            />

            <SideBarMenuStyle>
              {menuItems.map((menuItem) => {
                if (!pathnameSplit) return null

                if (menuItem.hidden) {
                  return null
                }

                if (menuItem.legacy && !isEnableBetaFeature) {
                  return null
                }

                if (menuItem.permissions && !hasPermissions(menuItem.permissions)) {
                  return null
                }

                if (!menuItem.submenu) {
                  return (
                    <MenuItem
                      key={menuItem.path}
                      path={menuItem.path ? `/${menuItem.path}` : undefined}
                      isActive={
                        (menuItem.path && pathnameSplit[1]?.startsWith(menuItem.path)) ||
                        menuItem.component?.activeState[0]
                      }
                      setActive={menuItem.component && menuItem.component.activeState[1]}
                      icon={menuItem.icon}
                      label={intl.formatMessage(menuItem.label)}
                      isBeta={menuItem.beta}
                    >
                      {menuItem.component &&
                        menuItem.component.render({
                          isActive: menuItem.component.activeState[0],
                          setActive: menuItem.component.activeState[1],
                        })}
                    </MenuItem>
                  )
                }

                const activePaths = [
                  menuItem.path,
                  ...menuItem.submenu
                    .filter((subConfig) => !!subConfig.overrideFullPath)
                    .map((subConfig) => subConfig.overrideFullPath),
                ]

                return (
                  <SubMenu
                    key={menuItem.path}
                    hasActiveChild={activePaths.includes(pathnameSplit[1])}
                    icon={menuItem.icon}
                    label={intl.formatMessage(menuItem.label)}
                  >
                    {menuItem.submenu.map((subConfig) => {
                      if (!pathnameSplit) return null

                      // @ts-ignore // TODO
                      if (subConfig.hidden) {
                        return null
                      }
                      if (subConfig.legacy && !isEnableBetaFeature) {
                        return null
                      }

                      if (subConfig.permissions && !hasPermissions(subConfig.permissions)) {
                        return null
                      }

                      const isActive = subConfig.overrideFullPath
                        ? pathnameSplit[1]?.startsWith(subConfig.overrideFullPath)
                        : pathnameSplit[2]?.startsWith(subConfig.path) &&
                          activePaths.includes(pathnameSplit[1])

                      return (
                        <MenuItem
                          key={subConfig.key || subConfig.path}
                          path={
                            subConfig.overrideFullPath
                              ? `/${subConfig.overrideFullPath}`
                              : `/${menuItem.path}/${subConfig.path}`
                          }
                          internal={subConfig.internal}
                          isActive={subConfig.internal ? isActive : false}
                          icon={subConfig.icon}
                          label={intl.formatMessage(subConfig.label)}
                          // @ts-ignore // TODO
                          isBeta={subConfig.beta}
                          href={subConfig.href}
                        />
                      )
                    })}
                  </SubMenu>
                )
              })}
            </SideBarMenuStyle>
          </SideBarWrapper>
        )
      }}
    </Location>
  )
}

export default SideBar
