export const PRODUCT_EXPORT = 'product.products.export'
export const PRODUCT_FORM = 'product.products.form'
export const PRODUCT_CREATE = 'product.products.create'
export const PRODUCT_UPDATE = 'product.products.update'
export const PRODUCT_DELETE = 'product.products.delete'
export const PRODUCT_DOCUMENT_EDIT = 'product.files.edit'
export const PRODUCT_DOCUMENT_DELETE = 'product.files.delete'
export const PRODUCT_SET_ARCHIVED = 'product.products.setArchived'
export const PRODUCT_SET_NAME = 'product.products.setName'
export const PRODUCT_SET_SERIAL = 'product.products.setSerial'
export const PRODUCT_SET_JAN_CODE = 'product.products.setJanCode'
export const PRODUCT_SET_HS_CODE = 'product.products.setHsCode'
export const PRODUCT_SET_MATERIAL = 'product.products.setMaterial'
export const PRODUCT_SET_CUSTOM_FIELDS = 'product.products.setCustomFields'
export const PRODUCT_SET_CUSTOM_FIELDS_MASK = 'product.products.setCustomFieldsMask'
export const PRODUCT_SET_TAGS = 'product.products.setTags'
export const PRODUCT_SET_MEMO = 'product.products.setMemo'
export const PRODUCT_SET_FOLLOWERS = 'product.products.setFollowers'
export const PRODUCT_PROVIDER_FORM = 'product.productProviders.form'
export const PRODUCT_PROVIDER_LIST = 'product.productProviders.list'
export const PRODUCT_PROVIDER_GET = 'product.productProviders.get'
export const PRODUCT_PROVIDER_GET_UNIT_TYPE = 'product.productProviders.getUnitType'
export const PRODUCT_PROVIDER_GET_UNIT_PRICE = 'product.productProviders.getUnitPrice'
export const PRODUCT_PROVIDER_CREATE = 'product.productProviders.create'
export const PRODUCT_PROVIDER_UPDATE = 'product.productProviders.update'

export const PRODUCT_PROVIDER_DOCUMENT_GET = 'product.productProviderFiles.get'
export const PRODUCT_PROVIDER_DOCUMENT_FORM = 'product.productProviderFiles.form'
export const PRODUCT_PROVIDER_DOCUMENT_EDIT = 'product.productProviderFiles.edit'
export const PRODUCT_PROVIDER_DOCUMENT_DOWNLOAD = 'product.productProviderFiles.download'
export const PRODUCT_PROVIDER_DOCUMENT_DELETE = 'product.productProviderFiles.delete'

export const PRODUCT_PROVIDER_SET_CUSTOM_FIELDS = 'product.productProviders.setCustomFields'
export const PRODUCT_PROVIDER_SET_CUSTOM_FIELDS_MASK =
  'product.productProviders.setCustomFieldsMask'
export const PRODUCT_PROVIDER_SET_NAME = 'product.productProviders.setName'
export const PRODUCT_PROVIDER_SET_TAGS = 'product.productProviders.setTags'
export const PRODUCT_PROVIDER_SET_MEMO = 'product.productProviders.setMemo'
export const PRODUCT_PROVIDER_SET_EXPORTER = 'product.productProviders.setExporter'
export const PRODUCT_PROVIDER_SET_INSPECTION_FEE = 'product.productProviders.setInspectionFee'
export const PRODUCT_PROVIDER_SET_ORIGIN = 'product.productProviders.setOrigin'
export const PRODUCT_PROVIDER_PACKAGE_SET_CAPACITY = 'product.productProviderPackages.setCapacity'
export const PRODUCT_PROVIDER_PACKAGE_SET_NAME = 'product.productProviderPackages.setName'
export const PRODUCT_PROVIDER_PACKAGE_SET_SIZE = 'product.productProviderPackages.setSize'
export const PRODUCT_PROVIDER_PACKAGE_SET_VOLUME = 'product.productProviderPackages.setVolume'
export const PRODUCT_PROVIDER_PACKAGE_SET_WEIGHT = 'product.productProviderPackages.setWeight'
export const PRODUCT_PROVIDER_SET_PRODUCTION_LEAD_TIME =
  'product.productProviders.setProductionLeadTime'
export const PRODUCT_PROVIDER_SET_SUPPLIER = 'product.productProviders.setSupplier'
export const PRODUCT_PROVIDER_SET_IMPORTER = 'product.productProviders.setImporter'
export const PRODUCT_PROVIDER_SET_UNIT_PRICE = 'product.productProviders.setUnitPrice'
export const PRODUCT_PROVIDER_SET_UNIT_SIZE = 'product.productProviders.setUnitSize'
export const PRODUCT_PROVIDER_SET_UNIT_TYPE = 'product.productProviders.setUnitType'
export const PRODUCT_PROVIDER_SET_UNIT_VOLUME = 'product.productProviders.setUnitVolume'
export const PRODUCT_PROVIDER_SET_UNIT_WEIGHT = 'product.productProviders.setUnitWeight'

export const PRODUCT_PROVIDER_PACKAGES_DELETE = 'product.productProviderPackages.delete'
export const PRODUCT_PROVIDER_SET_DEFAULT = 'product.productProviders.setDefaultPackage'
export const PRODUCT_PROVIDER_PACKAGES_CREATE = 'product.productProviderPackages.create'
export const PRODUCT_PROVIDER_PACKAGES_UPDATE = 'product.productProviderPackages.update'
