// @flow

import { useMutation, useQuery } from '@apollo/client'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'

import messageGroupMarkAsReadMutation from '@graphql/client/chat/mutation.messageGroupMarkAsRead.graphql'
import messageMarkAsReadMutation from '@graphql/client/chat/mutation.messageMarkAsRead.graphql'
import type {
  MinimalOrganizationFragment,
  UserCardFragment,
  MessageGroup,
} from '@graphql/server/flow'
import { unreadTimelineByEntity } from '@modules/TableView/CellRenderers/LogsRenderer/query'

import LoadingIcon from 'components/LoadingIcon'
import { clone } from 'utils/fp'

import ChatDetailSection from './components/ChatDetailSection'
import ChatList from './components/ChatList'
import { getEntityInput } from './helpers'
import { ChatContextProvider } from './hooks/useChat'
import entityFormMessageGroups from './query'
import { ChatDetailContainerStyle, ChatListSectionStyle, ChatStyle } from './style'
import type { ChatEntity } from './type'

type Props = {
  entityId: string,
  entityType: ChatEntity,
  defaultSelectedGroup?: string,
  relatedPartners: MinimalOrganizationFragment[],
  mentionUsers: UserCardFragment[],
  onListItemIconClick?: (string) => void,
}

const Chat = ({
  entityId,
  entityType,
  defaultSelectedGroup,
  relatedPartners,
  mentionUsers,
  onListItemIconClick,
}: Props): React.Node => {
  const [messageGroups, setMessageGroups] = React.useState<MessageGroup[]>([])

  const variables = useMemo(
    () => ({
      id: entityId,
    }),
    [entityId]
  )

  const { data: allPartnersUnreadData } = useQuery(
    unreadTimelineByEntity(entityType.toLowerCase()),
    {
      variables,
    }
  )

  // $FlowIgnore
  const entityFormMessageGroupsQuery = entityFormMessageGroups(entityType.toLowerCase())

  const { loading } = useQuery(entityFormMessageGroupsQuery, {
    variables,
    skip: entityType === 'Container',
    // $FlowIgnore
    onCompleted: (data) => {
      setMessageGroups((oldValue) => [
        ...oldValue,
        ...(data?.[entityType.toLowerCase()].messageGroups ?? []),
      ])
    },
  })

  const [selectedGroupId, setSelectedGroup] = useState<string>(defaultSelectedGroup || '')

  const [setMessageGroupRead] = useMutation(messageGroupMarkAsReadMutation)
  const [setOldMessageGroupRead] = useMutation(messageMarkAsReadMutation)

  // set local unread counts
  useEffect(() => {
    if (selectedGroupId) {
      setMessageGroupRead({
        variables: {
          messageGroupId: selectedGroupId,
        },
        update: (store: any) => {
          const data = clone(
            store.readQuery({
              query: entityFormMessageGroupsQuery,
              variables,
            })
          )

          if (data?.[entityType.toLowerCase()]) {
            data[entityType.toLowerCase()].messageGroups = data[
              entityType.toLowerCase()
            ].messageGroups.map((messageGroup) => {
              if (messageGroup.id === selectedGroupId) {
                return {
                  ...messageGroup,
                  unreadMessageCount: 0,
                }
              }
              return messageGroup
            })
          }

          store.writeQuery({
            query: entityFormMessageGroupsQuery,
            data,
            variables,
          })
        },
      })
    } else {
      setOldMessageGroupRead({
        variables: {
          entity: getEntityInput({ entityId, entityType }),
        },
        update: (store: any) => {
          const data = clone(
            store.readQuery({
              query: unreadTimelineByEntity(entityType.toLowerCase()),
              variables: {
                id: entityId,
              },
            })
          )

          if (data?.[entityType.toLowerCase()]) {
            data[entityType.toLowerCase()].timeline.unreadMessageCount = 0
          }

          store.writeQuery({
            query: unreadTimelineByEntity(entityType.toLowerCase()),
            data,
            variables,
          })
        },
      })
    }
  }, [
    selectedGroupId,
    entityId,
    entityType,
    entityFormMessageGroupsQuery,
    variables,
    setMessageGroupRead,
    setOldMessageGroupRead,
  ])

  // Get the organization ids from the selected message group.
  const selectedGroupOrganizationIds = new Set(
    messageGroups.find((mg) => mg.id === selectedGroupId)?.organizations.map((o) => o.id)
  )

  return (
    <ChatContextProvider
      entityId={entityId}
      entityType={entityType}
      selectedGroupId={selectedGroupId}
      relatedPartners={relatedPartners}
      mentionUsers={
        selectedGroupId
          ? mentionUsers.filter((mu) => selectedGroupOrganizationIds.has(mu.organization?.id))
          : mentionUsers
      }
    >
      <div className={ChatStyle}>
        <div className={ChatListSectionStyle}>
          {loading && <LoadingIcon />}
          {!loading && (
            <ChatList
              messageGroups={messageGroups}
              setMessageGroups={setMessageGroups}
              allPartnerUnreadCount={
                allPartnersUnreadData?.[entityType.toLowerCase()]?.timeline?.unreadMessageCount ?? 0
              }
              onListItemClick={(id) => setSelectedGroup(id)}
              onListItemIconClick={onListItemIconClick}
            />
          )}
        </div>
        <div className={ChatDetailContainerStyle}>
          <DndProvider backend={HTML5Backend} context={window}>
            <ChatDetailSection />
          </DndProvider>
        </div>
      </div>
    </ChatContextProvider>
  )
}

export default Chat
