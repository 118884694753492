// @flow

import { useApolloClient } from '@apollo/client'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { FormattedMessage, useIntl } from 'react-intl'

import shipmentFormFilesQuery from '@graphql/client/shipment/query.shipmentFormFiles.graphql'
import {
  type FileType,
  type DocumentFragment,
  type MinimalOrganizationFragment,
  type UserCardFragment,
  type MessageGroupFragment,
} from '@graphql/server/flow'

import { BaseButton } from 'components/Buttons'
import { getFileTypesByEntity } from 'components/Cards/DocumentCard'
import { CheckboxInput, SectionHeader } from 'components/Form'
import FormattedNumber from 'components/FormattedNumber'
import { SectionNavBar } from 'components/NavBar'
import OutsideClickHandler from 'components/OutsideClickHandler'
import { StickyScrollingSection } from 'components/Sections'
import { AllMainEntityTypeValues } from 'types'
import emitter from 'utils/emitter'
import { getByPathWithDefault } from 'utils/fp'
import logger from 'utils/logger'

import { DeliveryBoxSection, DocumentFileSection, DocumentNavbarContent } from './components'
import type { TabTypes, UploadFileState } from './components/DocumentNavbarContent'
import DocumentNavbarSecondRowContent from './components/DocumentNavbarSecondRowContent'
import {
  DocumentViewPreferenceDropdownWrapperStyle,
  DocumentViewPreferenceItemWrapperStyle,
} from './style'
import type { DocumentSortBy } from './type.js.flow'

type Props = {
  errorMessage?: string,
  files: $ReadOnlyArray<
    | DocumentFragment
    | $Exact<{|
        ...UploadFileState,
        tags: [],
        uploading: boolean,
        progress: number,
        isNew: boolean,
      |}>
  >,
  fileTypesForHighlight?: FileType[],
  defaultDocumentTab?: string,
  messageGroups?: MessageGroupFragment[],
  hideMessageBubble?: boolean,
  relatedPartners?: MinimalOrganizationFragment[],
  mentionUsers?: UserCardFragment[],
  entity:
    | (typeof AllMainEntityTypeValues)['Order']
    | (typeof AllMainEntityTypeValues)['OrderItem']
    | (typeof AllMainEntityTypeValues)['Shipment']
    | (typeof AllMainEntityTypeValues)['ProductProvider'],
  canUpload: boolean,
  canAddOrphan: boolean,
  canViewForm: boolean,
  canDownload: boolean,
  canChangeType: boolean,
  canDelete: boolean,
  isInDialog?: boolean,
  entityId?: string,
  label?: string,
  onSave?: (DocumentFragment[]) => void,
  onReinitialize?: (DocumentFragment[], MessageGroupFragment[]) => void,
}

const defaultMessageGroups: MessageGroupFragment[] = []

const DocumentsUpload = ({
  errorMessage,
  entityId,
  files,
  fileTypesForHighlight,
  defaultDocumentTab,
  messageGroups = defaultMessageGroups,
  hideMessageBubble,
  relatedPartners,
  mentionUsers,
  entity,
  canUpload,
  canAddOrphan,
  canViewForm,
  canDownload,
  canChangeType,
  canDelete,
  isInDialog = false,
  onSave,
  onReinitialize,
  label,
}: Props): React.Node => {
  const client = useApolloClient()
  const intl = useIntl()

  const [fileViewPreferences, setFileViewPreferences] = useState<
    { value: FileType, label: string, show: boolean }[]
  >(getFileTypesByEntity(entity, intl).map((item) => ({ ...item, show: true })))
  const [fileViewPreferencesIsOpen, setFileViewPreferencesIsOpen] = useState<boolean>(false)

  const [fileMessageGroups, setFileMessageGroups] = useState(messageGroups)
  const [sortBy, setSortBy] = useState<DocumentSortBy>({ updatedAt: 'DESCENDING' })

  React.useEffect(() => {
    setFileMessageGroups(messageGroups)
  }, [messageGroups])

  const [filesState, setFileState] = useState<
    {
      ...UploadFileState,
      tags: [],
      uploading: boolean,
      progress: number,
    }[]
  >([])

  const [isMultiSelect, setMultiSelect] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState<{ [k: string]: string }>({})

  const isAllSelected = filesState.length === Object.keys(selectedFiles).length
  const [tafileBValue, setTafileBValue] = useState<TabTypes>(
    defaultDocumentTab === 'deliveryBoxes' ? 'deliveryBoxes' : 'documentTypes'
  )

  useEffect(() => {
    const subscription = emitter.addListener('CHAT_LIST_ITEM_CLICK', (id: mixed) => {
      setTafileBValue(id ? 'deliveryBoxes' : 'documentTypes')
    })

    return () => subscription.remove()
  }, [])

  const reinitializeRef = React.useRef(onReinitialize)

  useEffect(() => {
    const subscription = emitter.addListener('MESSAGES_CLOSE', () => {
      client
        // $FlowFixMe
        .query({
          query: shipmentFormFilesQuery,
          variables: {
            id: entityId,
          },
          fetchPolicy: 'network-only',
        })
        .then((result) => {
          if (reinitializeRef.current) {
            reinitializeRef.current(
              result?.data?.shipment?.files ?? [],
              result?.data?.shipment?.messageGroups ?? []
            )
          }
        })
        .catch(logger.error)
    })

    return () => subscription.remove()
  }, [client, entityId])

  useEffect(() => {
    setFileMessageGroups(messageGroups)
  }, [messageGroups])

  const navbarContent = (
    <DocumentNavbarContent
      entityId={entityId}
      entityType={entity}
      relatedPartners={relatedPartners}
      canDownload={canDownload}
      selectedFiles={selectedFiles}
      setSelectedFiles={setSelectedFiles}
      isAllSelected={isAllSelected}
      filesState={filesState}
      defaultTab={tafileBValue}
      onTabChange={(newTab) => setTafileBValue(newTab)}
      isMultiSelect={isMultiSelect}
      onMultiSelectChange={(newState: boolean) => setMultiSelect(newState)}
      onPartnerGroupAdded={(newPartnerGroup) => {
        setFileMessageGroups((oldMessageGroups) => [newPartnerGroup, ...oldMessageGroups])
      }}
      onSave={onSave}
      isInDialog={isInDialog}
      label={label}
    />
  )

  const secondRowNavbarContent =
    tafileBValue === 'deliveryBoxes' ? (
      <DocumentNavbarSecondRowContent
        messageGroups={messageGroups}
        fileViewPreferences={fileViewPreferences}
        setFileViewPreferences={setFileViewPreferences}
        sortBy={sortBy}
        setSortBy={setSortBy}
      />
    ) : (
      <OutsideClickHandler
        onOutsideClick={() => setFileViewPreferencesIsOpen(false)}
        ignoreClick={fileViewPreferencesIsOpen === false}
      >
        <BaseButton
          icon="SETTINGS"
          label={<FormattedMessage id="components.button.view" defaultMessage="VIEW" />}
          backgroundColor="GRAY_SUPER_LIGHT"
          textColor="GRAY_DARK"
          hoverBackgroundColor="GRAY_DARK"
          borderRadius="5px"
          onClick={() => setFileViewPreferencesIsOpen((prev) => !prev)}
        />
        <div className={DocumentViewPreferenceDropdownWrapperStyle(fileViewPreferencesIsOpen)}>
          {fileViewPreferences.map((file) => (
            <div className={DocumentViewPreferenceItemWrapperStyle}>
              <CheckboxInput
                checked={file.show}
                onToggle={() =>
                  setFileViewPreferences((prev) => {
                    const next = [...prev]
                    const fileToChange = next.find((someFile) => someFile.value === file.value)
                    if (fileToChange) fileToChange.show = !fileToChange.show
                    return next
                  })
                }
              />
              <span>{file.label}</span>
            </div>
          ))}
        </div>
      </OutsideClickHandler>
    )

  const documentsBody = (
    <DndProvider backend={HTML5Backend} context={window}>
      {tafileBValue === 'deliveryBoxes' ? (
        //
        <DeliveryBoxSection
          entity={entity}
          entityId={entityId}
          hideMessageBubble={hideMessageBubble}
          messageGroups={[...fileMessageGroups].sort((fileA, fileB) => {
            let sortKey = Object.keys(sortBy)[0]
            const sortDirection = sortBy[sortKey]
            if (sortKey === 'documentQuantity') sortKey = 'files.length'

            const fileAVal = getByPathWithDefault(null, sortKey, fileA)
            const fileBVal = getByPathWithDefault(null, sortKey, fileB)

            if (fileAVal === undefined || fileBVal === undefined) return -1

            if (fileAVal >= fileBVal) {
              return sortDirection === 'DESCENDING' ? -1 : 1
            }
            return sortDirection === 'DESCENDING' ? 1 : -1
          })}
          setMessageGroups={setFileMessageGroups}
          relatedPartners={relatedPartners}
          mentionUsers={mentionUsers}
        />
      ) : (
        <DocumentFileSection
          isInDialog={isInDialog}
          entity={entity}
          files={files}
          filesState={filesState}
          fileTypesForHighlight={fileTypesForHighlight}
          fileViewPreferences={fileViewPreferences}
          setFileState={setFileState}
          setSelectedFiles={setSelectedFiles}
          isMultiSelect={isMultiSelect}
          selectedFiles={selectedFiles}
          onSave={onSave}
          canUpload={canUpload}
          canAddOrphan={canAddOrphan}
          canViewForm={canViewForm}
          canDownload={canDownload}
          canChangeType={canChangeType}
          canDelete={canDelete}
        />
      )}
    </DndProvider>
  )

  if (isInDialog) {
    return (
      <>
        <SectionNavBar>{navbarContent}</SectionNavBar>
        <p style={{ color: 'red' }}>{errorMessage}</p>
        {documentsBody}
      </>
    )
  }

  return (
    <StickyScrollingSection
      sectionHeader={
        <SectionHeader
          icon="DOCUMENT"
          title={
            <>
              <FormattedMessage id="components.section.documents" defaultMessage="Documents" /> (
              <FormattedNumber value={files.length} />)
            </>
          }
        />
      }
      navbarContent={navbarContent}
      secondRowcontent={secondRowNavbarContent}
    >
      <p style={{ color: 'red' }}>{errorMessage}</p>
      {documentsBody}
    </StickyScrollingSection>
  )
}

export default DocumentsUpload
