// @flow
import { navigate } from '@reach/router'
import * as React from 'react'

import { SHIPMENT_GET } from '@modules/permission/constants/shipment'

import { ShipmentCard } from 'components/Cards'
import { useEntityHasPermissions } from 'contexts/Permissions'
import { encodeId } from 'utils/id'

type Props = {
  shipment: Object,
}

const ParentShipmentCard = ({ shipment }: Props): React.Node => {
  const hasPermissions = useEntityHasPermissions(shipment)

  return (
    <ShipmentCard
      shipment={shipment}
      onClick={() => {
        if (hasPermissions(SHIPMENT_GET) && !!shipment?.id) {
          navigate(`/shipment/${encodeId(shipment?.id)}`)
        }
      }}
    />
  )
}

export default ParentShipmentCard
