// @flow
import { navigate } from '@reach/router'
import * as React from 'react'

import { ORDER_FORM } from '@modules/permission/constants/order'
import { ORDER_ITEMS_FORM, ORDER_ITEMS_GET_PRICE } from '@modules/permission/constants/orderItem'
import { PRODUCT_FORM } from '@modules/permission/constants/product'

import { ItemCard } from 'components/Cards'
import { useEntityHasPermissions } from 'contexts/Permissions'
import { encodeId } from 'utils/id'
import { spreadOrderItem } from 'utils/item'

type Props = {
  orderItem: Object,
}

const ParentItemCard = ({ orderItem }: Props): React.Node => {
  const hasItemPermissions = useEntityHasPermissions(orderItem)
  const hasOrderPermissions = useEntityHasPermissions(orderItem?.order)
  const hasProductPermissions = useEntityHasPermissions(orderItem?.productProvider?.product)

  return (
    <ItemCard
      {...spreadOrderItem(orderItem)}
      onClick={() => {
        if (hasItemPermissions(ORDER_ITEMS_FORM) && !!orderItem?.id) {
          navigate(`/order-item/${encodeId(orderItem?.id)}`)
        }
      }}
      viewable={{
        price: hasItemPermissions(ORDER_ITEMS_GET_PRICE),
      }}
      navigable={{
        order: hasOrderPermissions(ORDER_FORM),
        product: hasProductPermissions(PRODUCT_FORM),
      }}
    />
  )
}

export default ParentItemCard
