/* eslint-disable no-param-reassign */
// @flow
import produce from 'immer'

import type {
  Batch,
  BatchPayload,
  MetricValue,
  OrderItem,
  OrderItemPayload,
  Size,
} from '@graphql/server/flow'
import {
  BATCH_SET_CUSTOM_FIELDS,
  BATCH_SET_CUSTOM_FIELDS_MASK,
  BATCH_SET_TAGS,
  BATCH_UPDATE,
} from '@modules/permission/constants/batch'

import {
  DELIVERED_QUANTITY,
  INITIAL_QUANTITY,
  POST_SHIPPED_QUANTITY,
  PRE_SHIPPED_QUANTITY,
  PRODUCED_QUANTITY,
  SHIPPED_QUANTITY,
} from 'modules/batch/constants'

import { getByPathWithDefault } from './fp'
import { uuid } from './id'
import {
  convertDistance,
  convertVolume,
  convertWeight,
  defaultDistanceMetric,
  defaultVolumeMetric,
  defaultWeightMetric,
} from './metric'
import { divide, times } from './number'

export const findActiveQuantityField = ({
  producedQuantity,
  preShippedQuantity,
  shippedQuantity,
  postShippedQuantity,
  deliveredQuantity,
}: {
  producedQuantity: ?number,
  preShippedQuantity: ?number,
  shippedQuantity: ?number,
  postShippedQuantity: ?number,
  deliveredQuantity: ?number,
}): string => {
  if (deliveredQuantity) return DELIVERED_QUANTITY

  if (postShippedQuantity) return POST_SHIPPED_QUANTITY

  if (shippedQuantity) return SHIPPED_QUANTITY

  if (preShippedQuantity) return PRE_SHIPPED_QUANTITY

  if (producedQuantity) return PRODUCED_QUANTITY

  return INITIAL_QUANTITY
}

/**
 * @deprecated Use `batch.latestQuantity` property instead
 */
export const getBatchLatestQuantity = (batch: Partial<Batch>): number => {
  const quantity = batch?.[INITIAL_QUANTITY] ?? 0
  const deliveredQuantity = batch?.[DELIVERED_QUANTITY] ?? null
  const postShippedQuantity = batch?.[POST_SHIPPED_QUANTITY] ?? null
  const shippedQuantity = batch?.[SHIPPED_QUANTITY] ?? null
  const preShippedQuantity = batch?.[PRE_SHIPPED_QUANTITY] ?? null
  const producedQuantity = batch?.[PRODUCED_QUANTITY] ?? null

  if (deliveredQuantity !== null) return deliveredQuantity

  if (postShippedQuantity !== null) return postShippedQuantity

  if (shippedQuantity !== null) return shippedQuantity

  if (preShippedQuantity !== null) return preShippedQuantity

  if (producedQuantity !== null) return producedQuantity

  return quantity
}

export const findWeight = (batch: BatchPayload): any | number => {
  const packageGrossWeight = batch?.packageGrossWeight
  const packageQuantity = batch?.packageQuantity ?? 0
  return packageQuantity && packageGrossWeight
    ? times(
        packageQuantity,
        convertWeight(packageGrossWeight.value, packageGrossWeight.metric, 'kg')
      )
    : 0
}

export const findVolume = (batch: BatchPayload): any | number => {
  const volume = batch?.packageVolume
  const packageQuantity = batch?.packageQuantity ?? 0
  return packageQuantity && volume
    ? times(packageQuantity, convertVolume(volume.value, volume.metric, 'm³'))
    : 0
}

export const totalBatchPriceAmount = (batch: Batch): number => {
  const quantity = getBatchLatestQuantity(batch)
  return times(
    quantity,
    (batch.orderItem?.__typename === 'OrderItem' &&
      batch.orderItem.price.__typename === 'NewPrice' &&
      batch?.orderItem?.price?.amount) ??
      0
  )
}

export const calculateVolume = (volume: MetricValue, size: Size): MetricValue => {
  if (
    !getByPathWithDefault('', 'metric', volume) ||
    !getByPathWithDefault('', 'width.metric', size) ||
    !getByPathWithDefault('', 'height.metric', size) ||
    !getByPathWithDefault('', 'length.metric', size)
  ) {
    return volume
  }

  const metricToConvert = volume.metric === 'cm³' ? 'cm' : 'm'
  // $FlowIgnore ignore this error due to compare String vs literal string value
  const convertedHeight = convertDistance(size.height.value, size.height.metric, metricToConvert)
  // $FlowIgnore ignore this error due to compare String vs literal string value
  const convertedWidth = convertDistance(size.width.value, size.width.metric, metricToConvert)
  // $FlowIgnore ignore this error due to compare String vs literal string value
  const convertedDepth = convertDistance(size.length.value, size.length.metric, metricToConvert)

  const calculatedVolume = times(convertedHeight, convertedWidth, convertedDepth)

  return {
    metric: volume.metric,
    value: calculatedVolume,
    __typename: 'MetricValue',
  }
}

export const calculatePackageQuantity = (batch: $Shape<Batch>): any | number => {
  const quantity = getBatchLatestQuantity(batch)
  const packageCapacity = batch?.packageCapacity
  if (packageCapacity && packageCapacity > 0) {
    return divide(quantity, packageCapacity)
  }
  return 0
}

export const generateCloneBatch = (
  { id, deliveredAt, desiredAt, expiredAt, producedAt, no, tags, customFields, ...rest }: Batch,
  hasPermission: Function
) => {
  return {
    ...rest,
    id: uuid(),
    isNew: true,
    no: `${no} - clone`,
    tags: hasPermission([BATCH_UPDATE, BATCH_SET_TAGS]) ? tags : [],
    customFields: {
      ...customFields,
      fieldValues: hasPermission([BATCH_UPDATE, BATCH_SET_CUSTOM_FIELDS])
        ? customFields.fieldValues
        : [],
      mask: hasPermission([BATCH_UPDATE, BATCH_SET_CUSTOM_FIELDS_MASK]) ? customFields.mask : null,
    },
  }
}

export const totalVolume = (
  total: number,
  packageQuantity: number,
  volume: MetricValue
): any | number =>
  !volume || !packageQuantity
    ? total
    : // $FlowIgnore ignore this error due to compare String vs literal string value
      total + times(packageQuantity, convertVolume(volume.value, volume.metric, 'm³'))

export const findTotalAutoFillBatches = ({
  batches,
  quantity,
}: {
  batches: BatchPayload[],
  quantity: number,
}): Object => {
  const totalBatchQuantity = batches.reduce(
    (total, batch) => total + getBatchLatestQuantity(batch),
    0
  )
  return quantity - totalBatchQuantity
}

export const generateBatchByOrderItem = (orderItem: $Shape<OrderItem>): ?$Shape<Batch> => {
  let connectionBy
  let packageName
  let packageCapacity
  let packageGrossWeight
  let packageVolume
  let packageSize
  let autoCalculatePackageVolume

  if (orderItem.productProvider.__typename === 'ProductProvider') {
    connectionBy = orderItem.productProvider.connectionBy

    if (!connectionBy) return undefined

    if (orderItem.productProvider.defaultPackage?.__typename === 'ProductProviderPackage') {
      packageName =
        orderItem.productProvider.__typename === 'ProductProvider'
          ? orderItem?.productProvider?.defaultPackage?.name ?? ''
          : ''
      packageCapacity = orderItem?.productProvider?.defaultPackage?.capacity ?? 0
      packageGrossWeight = orderItem?.productProvider?.defaultPackage?.grossWeight ?? {
        metric: defaultWeightMetric,
        value: 0,
      }
      packageVolume = orderItem?.productProvider?.defaultPackage?.volume ?? {
        metric: defaultVolumeMetric,
        value: 0,
      }
      packageSize = orderItem?.productProvider?.defaultPackage?.size ?? {
        width: {
          metric: defaultDistanceMetric,
          value: 0,
        },
        height: {
          metric: defaultDistanceMetric,
          value: 0,
        },
        length: {
          metric: defaultDistanceMetric,
          value: 0,
        },
      }
      autoCalculatePackageVolume =
        orderItem?.productProvider?.defaultPackage?.autoCalculateVolume ?? true
    }
  }

  return {
    packageName,
    packageCapacity,
    packageGrossWeight,
    packageVolume,
    packageSize,
    autoCalculatePackageVolume,
    orderItem,
    connectionBy,
    packageQuantity: 0,
    id: uuid(),
    isNew: true,
    archived: false,
    autoCalculatePackageQuantity: true,
    no: '',
    tags: [],
    sort: 0,
    containerSort: 0,
    shipmentSort: 0,
    quantity: 0,
    latestQuantity: 0,
    customFields: {
      mask: null,
      fieldValues: [],
      fieldDefinitions: [],
    },
    createdAt: null,
    updatedAt: null,
    ownedBy: {},
    totalVolume: {
      metric: defaultVolumeMetric,
      value: 0,
    },
    __typename: 'Batch',
  }
}

export const autoFillBatch = (orderItem: OrderItemPayload, quantity: number): Batch => {
  const batch = {
    ...generateBatchByOrderItem(orderItem),
    latestQuantity: quantity,
    quantity,
  }
  return {
    ...batch,
    packageQuantity: calculatePackageQuantity(batch),
  }
}

export const updateBatchCardQuantity = (batch: Batch, quantity: number): Batch => {
  const autoCalculatePackageQuantity = batch?.autoCalculatePackageQuantity ?? true
  const field: string = findActiveQuantityField({
    producedQuantity: batch?.producedQuantity,
    preShippedQuantity: batch?.preShippedQuantity,
    shippedQuantity: batch?.shippedQuantity,
    postShippedQuantity: batch?.postShippedQuantity,
    deliveredQuantity: batch?.deliveredQuantity,
  })

  return produce(batch, (draft) => {
    draft[field] = quantity
    if (autoCalculatePackageQuantity) {
      draft.packageQuantity = calculatePackageQuantity(draft)
    }
  })
}
