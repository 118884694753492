// @flow strict

import { useMutation, useQuery } from '@apollo/client'
import * as React from 'react'
import { useEffect, useRef } from 'react'
import { cx } from 'react-emotion'
import { useIntl } from 'react-intl'
import type { LocalFile } from 'types/file'

import draftMessageUpdateMutation from '@graphql/client/chat/mutation.draftMessageUpdate.graphql'
import messageCreateMutation from '@graphql/client/chat/mutation.messageCreate.graphql'
import messageUpdateMutation from '@graphql/client/chat/mutation.messageUpdate.graphql'
import messagePreferencesQuery from '@graphql/client/chat/query.messagePreferences.graphql'
import type {
  DraftMessageUpdateMutation,
  DraftMessageUpdateMutationVariables,
  FileType,
  MessagePreferencesQuery,
  MessagePreferencesQueryVariables,
  MessageCreateMutation,
  MessageCreateMutationVariables,
  MessageUpdateMutation,
  MessageUpdateMutationVariables,
} from '@graphql/server/flow'
import entityHasFile from '@utils/entityHasFile'

import Divider from 'components/Divider'
import GridRow from 'components/GridRow'
import Icon from 'components/Icon'
import useDebounce from 'hooks/useDebounce'
import { useChat } from 'modules/chat/hooks/useChat'
import messages from 'modules/chat/messages'
import {
  ChatInputActionsStyle,
  ChatInputContainerStyle,
  ChatSvgStyle,
  ChatTextInputStyle,
} from 'modules/chat/style'
import SubmitMenu from 'modules/timeline/components/CommentInput/submitMenu'
import { isEnableBetaFeature } from 'utils/env'
import { eventFilesToLocalFiles } from 'utils/typeConversions/eventFilesToLocalFiles'

import { getEntityInput } from '../../helpers'
import DocumentUploadInput from '../DocumentUploadInput'
import UploadMenuDropdown from '../UploadMenuDropdown'

import InputField from './InputField'

type Props = {|
  fileType: ?FileType,
  localFiles: LocalFile[],
  setLocalFiles: (((LocalFile[]) => LocalFile[]) | LocalFile[]) => void,
  onUploadFiles: (LocalFile[], ?FileType) => void,
  resetFiles: () => void,
  setFileType: (((FileType | void) => FileType | void) | FileType | void) => void,
|}

const ChatInput = ({
  localFiles,
  setLocalFiles,
  fileType,
  setFileType,
  resetFiles,
  onUploadFiles,
}: Props): React.Node => {
  const {
    state: { messageContent, mode, selectedMessageId, draftMessage },
    dispatch,
    entityType,
    entityId,
    mentionUsers,
    selectedGroupId: messageGroupId,
  } = useChat()

  const intl = useIntl()

  const [updateDraftMessage] = useMutation<
    DraftMessageUpdateMutation,
    DraftMessageUpdateMutationVariables
  >(draftMessageUpdateMutation, {
    onCompleted: (res) => {
      dispatch({
        type: 'SET_DRAFT_MESSAGE',
        payload: {
          draftMessage:
            res.draftMessage.__typename === 'DraftMessage' ? res.draftMessage.content : '',
        },
      })
    },
  })

  const debouncedText = useDebounce(messageContent, 5000)

  useEffect(() => {
    if (
      draftMessage !== null && // if fetched from remote...
      draftMessage !== undefined &&
      debouncedText !== draftMessage && // ...and there is a difference...
      mode === 'NORMAL_MODE' // ...and in the correct mode, then:
    )
      updateDraftMessage({
        variables: {
          input: {
            entity: getEntityInput({ entityId, entityType }),
            content: debouncedText,
            messageGroupId,
          },
        },
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedText])

  const textAreaRef = useRef<?HTMLDivElement>(null)

  const { data: sendMessageEnterData, refetch } = useQuery<
    MessagePreferencesQuery,
    MessagePreferencesQueryVariables
  >(messagePreferencesQuery)
  const sendMessageOnEnter =
    sendMessageEnterData?.viewer.messagePreferences.sendMessageByEnter ?? true

  const [messageCreate, { loading: isCreating }] = useMutation<
    MessageCreateMutation,
    MessageCreateMutationVariables
  >(messageCreateMutation, {
    onCompleted: ({ messageCreate: newMessage }) => {
      if (newMessage.__typename !== 'Message') return

      if (textAreaRef.current) {
        textAreaRef.current.focus()
      }
    },
  })

  const [messageUpdate, { loading: isUpdating }] = useMutation<
    MessageUpdateMutation,
    MessageUpdateMutationVariables
  >(messageUpdateMutation, {
    onCompleted: ({ messageUpdate: updatedMessage }) => {
      if (updatedMessage.__typename !== 'Message') return
      dispatch({
        type: 'UPDATE_MESSAGE',
        payload: {
          messageId: selectedMessageId,
          message: updatedMessage,
        },
      })
    },
  })

  const onEnterText = () => {
    if (!messageContent) {
      return
    }

    if (mode === 'EDIT_MODE') {
      messageUpdate({
        variables: {
          id: selectedMessageId,
          input: {
            content: messageContent,
          },
        },
      })
    } else {
      messageCreate({
        variables: {
          input: {
            content: messageContent,
            ...(!!messageGroupId && { messageGroupId }),
            entity: {
              [`${entityType.toLowerCase()}Id`]: entityId,
            },
          },
        },
      })
    }

    dispatch({
      type: 'SET_MESSAGE_CONTENT',
      payload: {
        content: '',
      },
    })
  }

  return (
    <>
      {!!localFiles.length && (
        <DocumentUploadInput
          fileType={fileType}
          setFileType={setFileType}
          localFiles={localFiles}
          resetFiles={resetFiles}
          onUpload={() => {
            onUploadFiles(localFiles, fileType)
            resetFiles()
          }}
        />
      )}
      <div className={ChatInputContainerStyle}>
        {isEnableBetaFeature && (
          <>
            <div className={ChatSvgStyle}>
              <GridRow gap="10px">
                <Icon icon="BOLD" />
                <Icon icon="ITALIC" />
                <Icon icon="STRIK_THROUGH" />
                <Icon icon="LINK" />
                <Icon icon="LIST" />
                <Icon icon="LIST_OL" />
              </GridRow>
            </div>
            <Divider />
          </>
        )}
        <div
          className={ChatTextInputStyle}
          onPaste={(e) => {
            const clipboardData = e.clipboardData || window.clipboardData
            const files: File[] = []

            let isPastingFile = false
            Array.from(clipboardData.items).forEach((item) => {
              if (item.kind === 'file') {
                const file = item.getAsFile()
                files.push(file)
                isPastingFile = true
              }
            })

            // Process file pasting separately
            if (isPastingFile) {
              e.preventDefault()
              setLocalFiles((old) => [...old, ...eventFilesToLocalFiles(files, entityType)])
            }
          }}
        >
          <InputField
            textAreaRef={textAreaRef}
            disabled={isCreating || isUpdating}
            placeholder={intl.formatMessage(messages.inputPlaceholder)}
            value={messageContent}
            onBlur={() => {
              if (messageContent !== draftMessage && mode === 'NORMAL_MODE') {
                updateDraftMessage({
                  variables: {
                    input: {
                      entity: getEntityInput({ entityId, entityType }),
                      content: messageContent,
                      messageGroupId,
                    },
                  },
                })
              }
            }}
            onChange={(e) =>
              dispatch({
                type: 'SET_MESSAGE_CONTENT',
                payload: {
                  content: e.target.value,
                },
              })
            }
            users={mentionUsers}
            onKeyDown={(e) => {
              e.stopPropagation()

              if (sendMessageOnEnter) {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault()
                  onEnterText()
                }
              } else if (e.keyCode === 13 && (e.metaKey || e.ctrlKey)) {
                e.preventDefault()
                onEnterText()
              }
            }}
          />
        </div>
        <div className={cx(ChatSvgStyle, ChatInputActionsStyle)}>
          <GridRow gap="10px">
            <SubmitMenu sendMessageOnEnter={sendMessageOnEnter} refetch={refetch}>
              <span type="button" disabled={false}>
                <Icon icon="HORIZONTAL_ELLIPSIS" />
              </span>
            </SubmitMenu>
            {isEnableBetaFeature && <Icon icon="SMILE" />}
            {entityHasFile(entityType) && <UploadMenuDropdown setLocalFiles={setLocalFiles} />}
            <Icon icon="PAPER_PLANE" onClick={onEnterText} />
          </GridRow>
        </div>
      </div>
    </>
  )
}

export default ChatInput
