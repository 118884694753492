import { TypedDocumentNode } from '@apollo/client'
import { HasPermissions } from 'contexts/Permissions'

import orderDocumentsQuery from '@graphql/client/order/query.ordersByIDsDocuments.graphql'
import orderItemDocumentsQuery from '@graphql/client/orderItem/query.orderItemsByIDsDocuments.graphql'
import productProviderDocumentsQuery from '@graphql/client/productProvider/query.productProvidersByIDsDocuments.graphql'
import shipmentDocumentsQuery from '@graphql/client/shipment/query.shipmentsByIDsDocuments.graphql'

import { PARENTLESS_DOCUMENT_UPLOAD } from '@modules/permission/constants/file'
import {
  ORDER_DOCUMENT_DELETE,
  ORDER_DOCUMENT_DOWNLOAD,
  ORDER_DOCUMENT_EDIT,
  ORDER_DOCUMENT_FORM,
  ORDER_UPDATE,
} from '@modules/permission/constants/order'
import {
  ORDER_ITEMS_DOCUMENT_DELETE,
  ORDER_ITEMS_DOCUMENT_DOWNLOAD,
  ORDER_ITEMS_DOCUMENT_EDIT,
  ORDER_ITEMS_DOCUMENT_FORM,
  ORDER_ITEMS_UPDATE,
} from '@modules/permission/constants/orderItem'
import {
  SHIPMENT_DOCUMENT_DELETE,
  SHIPMENT_DOCUMENT_DOWNLOAD,
  SHIPMENT_DOCUMENT_EDIT,
  SHIPMENT_DOCUMENT_FORM,
  SHIPMENT_EDIT,
} from '@modules/permission/constants/shipment'
import { DocumentedEntity } from '@types'

export function checkDocumentActions(type: string, hasPermission: HasPermissions) {
  let canUpload = true
  let canDelete = true
  let canAddOrphan = true
  let canChangeType = true
  let canDownload = true
  let canViewForm = false

  switch (type) {
    case 'Order': {
      canAddOrphan = hasPermission([ORDER_DOCUMENT_EDIT, ORDER_UPDATE])
      canChangeType = hasPermission([ORDER_DOCUMENT_EDIT, ORDER_UPDATE])
      canUpload = hasPermission(PARENTLESS_DOCUMENT_UPLOAD) && canChangeType
      canViewForm = hasPermission(ORDER_DOCUMENT_FORM)
      canDownload = hasPermission(ORDER_DOCUMENT_DOWNLOAD)
      canDelete = hasPermission(ORDER_DOCUMENT_DELETE)
      break
    }

    case 'OrderItem': {
      canAddOrphan = hasPermission([ORDER_ITEMS_DOCUMENT_EDIT, ORDER_ITEMS_UPDATE])
      canChangeType = hasPermission([ORDER_ITEMS_DOCUMENT_EDIT, ORDER_ITEMS_UPDATE])
      canUpload = hasPermission(PARENTLESS_DOCUMENT_UPLOAD) && canChangeType
      canViewForm = hasPermission(ORDER_ITEMS_DOCUMENT_FORM)
      canDownload = hasPermission(ORDER_ITEMS_DOCUMENT_DOWNLOAD)
      canDelete = hasPermission(ORDER_ITEMS_DOCUMENT_DELETE)
      break
    }

    case 'Shipment': {
      canAddOrphan = hasPermission([SHIPMENT_DOCUMENT_EDIT, SHIPMENT_EDIT])
      canChangeType = hasPermission([SHIPMENT_DOCUMENT_EDIT, SHIPMENT_EDIT])
      canUpload = hasPermission(PARENTLESS_DOCUMENT_UPLOAD) && canChangeType
      canViewForm = hasPermission(SHIPMENT_DOCUMENT_FORM)
      canDownload = hasPermission(SHIPMENT_DOCUMENT_DOWNLOAD)
      canDelete = hasPermission(SHIPMENT_DOCUMENT_DELETE)
      break
    }

    default:
      break
  }
  return {
    canDelete,
    canUpload,
    canAddOrphan,
    canChangeType,
    canDownload,
    canViewForm,
  }
}

export const getDocumentQuery = (entityType: DocumentedEntity['__typename']): TypedDocumentNode => {
  switch (entityType) {
    case 'Order':
      return orderDocumentsQuery
    case 'OrderItem':
      return orderItemDocumentsQuery
    case 'Shipment':
      return shipmentDocumentsQuery
    case 'ProductProvider':
    default:
      return productProviderDocumentsQuery
  }
}

export const getDocumentsFromQueryData = (
  entityType: DocumentedEntity['__typename'],
  data: any
) => {
  let key = 'shipmentsByIDs'

  switch (entityType) {
    case 'Order':
      key = 'ordersByIDs'
      break
    case 'OrderItem':
      key = 'orderItemsByIDs'
      break
    case 'ProductProvider':
      key = 'productProvidersByIDs'
      break
    default:
  }

  return {
    files: data?.[key]?.[0]?.files ?? [],
    messageGroups: data?.[key]?.[0]?.messageGroups ?? [],
  }
}
